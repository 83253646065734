.wrapper {
  position: relative;
  width: 100%;
  max-width: $page-max-width;
  margin-left: auto;
  margin-right: auto; }

.page {
  @include clearfix;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin: 0 auto;
  &:not(.home-page) {
    padding-top: 70px; } }

.two-up {
  @include clearfix;
  margin-left: -$fixed-gutter !important;
  margin-right: -$fixed-gutter !important;
  .cell {
    float: left;
    width: 100%;
    padding-right: $fixed-gutter !important;
    padding-left: $fixed-gutter !important;
    margin-bottom: $fixed-gutter*2 !important;
    @include bp(min, $bp-3) {
      width: 50%;
      &:nth-child(2n+1) {
        clear: left; } } } }

.three-up {
  @include clearfix;
  margin-left: -$fixed-gutter !important;
  margin-right: -$fixed-gutter !important;
  .cell {
    float: left;
    width: 100%;
    padding-right: $fixed-gutter !important;
    padding-left: $fixed-gutter !important;
    margin-bottom: $fixed-gutter*2 !important;
    @include bp(min, $bp-3) {
      width: 50%;
      &:nth-child(2n+1) {
        clear: left; } }
    @include bp(min, $bp-5) {
      width: (100/3) * 1%;
      &:nth-child(2n+1) {
        clear: none; }
      &:nth-child(3n+1) {
        clear: left; } } } }

.three-up--centered {
  text-align: center;
  .cell {
    display: inline-block;
    vertical-align: top;
    float: none;
    text-align: left; } }

.four-up {
  @include clearfix;
  margin-left: -$fixed-gutter !important;
  margin-right: -$fixed-gutter !important;
  .cell {
    float: left;
    width: 100%;
    padding-right: $fixed-gutter !important;
    padding-left: $fixed-gutter !important;
    margin-bottom: $fixed-gutter*2 !important;
    @include bp(min, $bp-3) {
      width: 50%;
      margin-bottom: rem(20);
      &:nth-child(2n+1) {
        clear: left; } }
    @include bp(min, $bp-5) {
      width: (100/4) * 1%;
      &:nth-child(2n+1) {
        clear: none; }
      &:nth-child(4n+1) {
        clear: left; } } } }

.margin-bottom--small {
  display: inline-block;
  margin-bottom: em(20); }

.margin-bottom--big {
  @include unit(margin-bottom, 1); }

.merchant-info {
  margin-top: em(20);
  margin-bottom: em(20); }

.form-fields {
  margin-top: em(10);
  margin-bottom: em(10);
  @include bp(min, $bp-3) {
    margin-top: em(20);
    margin-bottom: em(20); } }

