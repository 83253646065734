.friend-tier {
  margin-bottom: em(30);
  // +bp(min, $bp-3)
  //   padding: 0 em(60)
  .friend-tier__header {
    background: $color-red;
    color: white;
    padding: em(40);
    .price {
      font-size: $font-size-huge; } }
  .friend-tier__body {
    ol {
      margin-left: 1em;
      list-style: none;
      counter-reset: item;
      li {
        background:red {}
        margin-bottom: 5px;
        counter-increment: item;
        &:before {
          margin-right: .2em;
          content: counter(item) ".";
          margin-left: -1em;
          font-weight: 700; } } }
    .tier-class {
      padding: em(20) 0; } }
  .switch-interval {
  	display: block; }
  .switch-interval-button {
  	cursor: pointer;
  	&.active {
    	color: $color-red; } } }

.tiers--half-width {
	text-align: center;
	.module__inner {
    	@include unit(max-width, 9);
    	display: inline-block;
    	width: 100%;
    	margin-bottom: -($fixed-gutter*2) !important;
    	@include bp(max, $bp-3) {
      		@include unit(max-width, 10);
      		@include unit(padding-left, 0);
      		@include unit(padding-right, 0); } } }
.subscribe-now {
	cursor: pointer; }

.subscribe-now--button {
	background: $color-red;
	color: white;
	font-weight: 700;
	text-transform: uppercase;
	padding: 0.6em 1em;
	cursor: pointer;
	display: inline-block; }
