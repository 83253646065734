.notification {
  padding-top: em(20);
  padding-bottom: em(20); }

.notification .flash {
  margin-left: em(20);
  margin-right: em(20);
  padding: em(10);
  color: #000;
  @include bp(min, $bp-3) {
    margin-left: em(40);
    margin-right: em(40); }
  &:empty {
    display: none; } }

.flash--error {
  background: $color-light-red; }

.flash--notification {
  background: $color-light-grey; }
