.button {
  @include type-m-to-ml;
  color: $black;
  padding: rem(8) rem(20);
  margin-bottom: 1em;
  appearance: none;
  border: 2px solid currentColor;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: normal;
  display: block;
  text-align: left;
  &:hover {
    color: $color-red; }
  @include bp(min, $bp-3) {
    padding: em(14) em(15); } }

.button span {
  float: right; }

.button--small {
  padding: rem(8) rem(10);
  font-size: rem(16); }

.button--primary {
  display: inline-block;
  float: right;
  margin-top: em(20);
  width: 100%;
  @include bp(min, $bp-3) {
    max-width: 280px; } }

form .button {
  font-family: $font-family;
  background: #fff; }

.teaser--product form .button {
  width: 100%; }

button.ajax {
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;
  &:active {
    background: #000000; }
  &:hover {
    color: #ffffff; }
  .button__text {
    transition: all 0.2s; }
  &:disabled {
    background: lighten($color-red, 20%);
    cursor: default; } }

.button--loading {
  .button__text {
    visibility: hidden;
    opacity: 0; } }

.button--loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite; }
