.address-select {
  margin-bottom: 1em;
  position: relative;
  border-radius: 4px; }

.address-select .radio input {
  margin: 0 5px 0 0;
  position: relative;
  top: -1px; }

.address-select .radio {
  padding: 14px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.address-select ul.address-data {
  display: inline; }

.address-select ul.address-data li {
  display: inline; }

.address-select ul.address-data li {
  display: inline; }

.address-select ul.address-data li strong {
  display: none; }

.address-select ul.address-data li span[data-key=firstName] {
  font-weight: bold; }

.address-select ul.address-data li span[data-key=lastName] {
  font-weight: bold; }

.address-select .actions {
  float: right;
  padding: 14px 15px;
  font-size: 0.9em; }

.address-select .actions ul {
  margin: 0; }

.address-select .actions ul li {
  display: inline-block;
  margin: 0 0 0 1em; }

.address-select .actions ul li a {
  color: #999;
  text-decoration: underline; }
