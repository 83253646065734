.steps {
  margin-bottom: em(20);
  ul {
    display: flex;
    display: -webkit-flex;
    margin-bottom: 0;
    li {
      list-style: none;
      display: block;
      flex: 1;
      color: #000;
      a {
        color: #000; }
      &.done {
        color: $color-red;
        opacity: .5;
        a {
          color: $color-red; } }
      &.sel {
        color: $color-red;
        opacity: 1;
        a {
          color: $color-red; } } } }
  .meter {
    height: 10px;
    position: relative;
    padding: 3px;
    margin-bottom: 10px;
    background: #f6f6f6;
    > span {
      display: block;
      height: 100%;
      background-color: $color-red;
      position: relative;
      overflow: hidden; } } }

@media (max-width: 750px) {
  .steps {
    ul {
      display: block;
      margin-left: 24px;
      li {
        list-style: decimal;
        display: list-item; } }
    .meter {
      display: none; } } }
