input[type=number].cart__input-quantity {
  width: 70px;
  height: 44px;
  border-radius: 0;
  margin-right: -6px; }

input[type=text].cart__input-voucher {
  height: 44px;
  border-radius: 0;
  margin-right: -6px; }

input[type=text].cart__input-email {
  background-color: #fff; }

.cart__empty-cart {
  float: left;
  margin-top: em(20); }
