.category-nav {
  @include unit(max-width, 12);
  @include unit(padding-left, 0.5);
  @include unit(padding-right, 0.5);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(20);
  @include bp(min, $bp-3) {
    margin-bottom: rem(40); } }

.category-nav__list {
  padding-left: em(20);
  padding-right: em(20);
  list-style: none;
  @include bp(min, $bp-3) {
    padding: 0 em(40); } }

.category-nav__list__item {
  margin-right: em(16);
  margin-bottom: em(4);
  font-size: rem(16);
  @include bp(min, $bp-3) {
    display: inline-block;
    font-size: rem(18); } }

.category-nav__list__item--home {
  @include bp(min, $bp-3) {
    border-right: 1px solid #000; } }

.category-nav__list__item--home a {
  margin-right: em(20);
  padding-bottom: em(2); }

.category-nav__list .active {
  border-bottom: 1px solid #000; }
