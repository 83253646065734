.instagram {
  margin-top: rem(20);
  margin-bottom: rem(20); }

.instagram a {
  display: inline-block;
  margin-left: rem(10);
  margin-right: rem(10);
  margin-bottom: rem(5); }

.instagram img {
  display: block; }
