.newsletter-wrapper {
  @include unit(padding-top, 0.25);
  @include unit(padding-bottom, 0.5);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto; }

.newsletter-text {
  max-width: 750px;
  @include bp(min, $bp-3) {
    margin: 0 auto; }
  @include type-m-to-ml;
  a, a:link {
    border-bottom: 1px solid #000;
    @include transition(color, 0.25s);
    &:hover {
      border-bottom: none;
      color: $color-red; } } }

.newsletter-form {
  display: flex;
  flex-direction: row;
  margin: 1.25em 0; }

.newsletter-form__email[type=email] {
  @include type-m-to-ml;
  display: block;
  border-radius: 0;
  flex: 1;
  height: 44px;
  appearance: none;
  border: 2px solid currentColor;
  border-right: none !important;
  margin-right: 0;
  margin-bottom: 0;
  padding: rem(8) rem(20);
  @include bp(min, $bp-3) {
    height: 69px;
    padding: em(14) em(15); } }

.newsletter-form__submit {
  width: 25%;
  height: 44px;
  max-width: 300px;
  border-radius: 0;
  min-width: 80px;
  text-align: center;
  background-color: $color-light-red !important;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  @include bp(min, $bp-3) {
    height: 69px; }
  &:hover {
    color: $black !important;
    background-color: $white !important;
    border-color: $black !important; } }
