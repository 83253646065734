.lightred-teaser__box {
  @include unit(padding-left, 1);
  @include unit(padding-top, 1);
  @include unit(padding-right, 1);
  @include unit(padding-bottom, 2);
  @include unit(margin-bottom, .7);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background: $color-light-red;
  text-align: center;
  @include bp(min, $bp-3) {
    @include unit(padding-left, .25);
    @include unit(padding-top, .5);
    @include unit(padding-right, .25);
    @include unit(padding-bottom, 1); }
  @include bp(min, $bp-5) {
    @include unit(padding-bottom, .5); }
  &.is-constrained {
    @include unit(max-width, 10); } }

.lightred-teaser__box__headline {
  @include type-ml-to-xl;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  font-weight: 400;
  max-width: 400px; }

.lightred-teaser__box__date {
  @include unit(margin-bottom, .25);
  @include type-ml-to-xl;
  font-weight: 200;
  &:before {
    content: '→';
    margin-right: em(4); } }

.lightred-teaser__box__signup {
  position: absolute;
  width: 75%;
  margin-bottom: -20px;
  background: #fff;
  box-shadow: #fff 15px -15px 0px, #fff -15px -15px 0;
  display: inline-block;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  text-align: left;
  @include bp(min, $bp-3) {
    width: 40%;
    margin-bottom: -30px;
    box-shadow: #fff 25px -25px 0px, #fff -25px -25px 0; } }
