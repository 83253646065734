.product-detail {
  @include unit(max-width, 10);
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0; }

.product-detail h1 {
  @include type-ml-to-l;
  font-weight: 400;
  margin-bottom: em(20);
  @include bp(min, $bp-3) {
    @include unit(max-width, 4);
    @include unit(margin-left, 4.5);
    padding-left: 2rem; } }

.product-detail input {
  background: #fff; }
