.housenumbers-container {
  @include unit(max-width, 12);
  @include unit(padding-left, 0.5);
  @include unit(padding-right, 0.5);
  @include unit(margin-bottom, 0.25);
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0; }

.housenumbers__hint {
  margin-bottom: .5em;
  margin-left: 0;
  color: $color-caption; }

.housenumbers__instructions-toggle {
  font-family: $font-family;
  background: none;
  margin-left: auto;
  margin-right: auto; }

.housenumbers__instructions {
  display: none; }

.housenumbers__tester {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  padding-left: em(20);
  padding-right: em(20);
  @include bp(min, $bp-3) {
    padding: 0 em(40); } }

.housenumbers__input {
  @include appearance(none);
  border: none;
  width: 100%;
  text-align: center;
  padding: .15em;
  font-size: 16vw;
  background: white;
  margin-bottom: 10px;
  line-height: 1.2;
  background: $color-light-grey;
  text-shadow: rgba(0,0,0,0.25) 3px 3px 3px;
  &:focus {
    background: $color-light-grey;
    border: none;
    outline: 0; }
  @include bp(min, $max-width--page) {
    font-size: $max-width--page / 7 * 1px; } }

.housenumbers__nav__label {
  @include type-xs-to-m;
  display: inline-block;
  max-width: 15vw;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 2px; }

input:checked + .housenumbers__nav__label {
  color: $color-red; }

.housenumbers__nav__radio[type=radio] {
  display: none; }

.housenumbers__nav__label--for-industrial,
.housenumbers__nav__label--for-classic {
  display: none; }

.housenumbers__nav__label--color {
  width: 2em;
  height: 2em;
  border: none;
  border-radius: 50%;
  background-color: black;
  text-indent: -9999px;
  overflow: hidden;
  border: 2px solid white !important; }

input:checked + .housenumbers__nav__label--color {
  border-color: rgba(black, .5)  !important; }

.housenumbers__nav__label--red {
  margin-left: auto;
  background-color: $color-red; }

.housenumbers__nav__label--orange {
  margin-left: auto;
  background-color: #DF4916; }

.housenumbers__nav__label--black {
  background-color: black; }

.housenumbers__nav__label--olive {
  background-color: #273A2C; }


#hn-classic:checked {
  ~ .housenumbers__input {
    font-family: 'HN-Classic', 'Blank'; }
  ~ .housenumbers__nav__label--for-classic {
    display: inline-block; }
  ~ #hn-red:checked {
    ~ .housenumbers__input {
      color: $color-red; } }
  ~ #hn-olive:checked {
    ~ .housenumbers__input {
      color: #273A2C; } } }

#hn-contemporary:checked {
  ~ .housenumbers__input {
    font-family: 'HN-Contemporary', 'Blank';
    color: #242424; } }

#hn-tech:checked {
  ~ .housenumbers__input {
    font-family: 'HN-Tech', 'Blank';
    color: #B0B0B0; } }

#hn-industrial:checked {
  ~ .housenumbers__input {
    font-family: 'HN-Industrial', 'Blank'; }
  ~ .housenumbers__nav__label--for-industrial {
    display: inline-block; }
  ~ #hn-orange:checked {
    ~ .housenumbers__input {
      color: #DF4916; } }
  ~ #hn-black:checked {
    ~ .housenumbers__input {
      color: black; } } }

#instructions:target {
  display: block;
  ~ .housenumbers__instructions-toggle {
    display: none; } }

.housenumbers__instructions-toggle {
  display: inline-block; }
