.specimen-container {
  @include unit(max-width, 12);
  margin-left: auto;
  margin-right: auto; }

.specimen {
  display: block;
  margin-left: auto;
  margin-right: auto;
  .module__inner {
    @include unit(max-width, 10);
    @include unit(padding-left, 0);
    @include unit(padding-right, 0);
    @include bp(min, $bp-3) {
      @include unit(max-width, 8); } } }

.specimen--about {
  background: #fafafa; }

.specimen--recommendations {
  background: #f2f2f2; }

.specimen--styles {
  background: #ededed; }

.specimen .module__inner {
  @include bp(min, $bp-3) {
    @include unit(max-width, 8); } }

.specimen__table {
  display: inline-block;
  width: 100%;
  max-width: 880px;
  margin-bottom: rem(20); }

.specimen__table__cell {
  @include type-s-to-m;
  float: left;
  width: 100%;
  @include bp(min, $bp-3) {
    width: 50%;
    padding-top: rem(6);
    padding-bottom: rem(6);
    border-bottom: 1px solid #dadada; } }

.specimen__table__cell:nth-child(2n+2) {
  @include bp(max, $bp-3) {
    margin-bottom: rem(10);
    padding-bottom: rem(10);
    border-bottom: 1px solid #dadada; } }

.specimen h3 {
  @include type-ml-to-xl;
  margin-bottom: rem(14);
  font-weight: 300; }

.specimen h4 {
  @include type-ml-to-l;
  margin-bottom: rem(14);
  font-weight: 300; }

.specimen .body-text {
  width: 100%;
  max-width: 100%;
  p,ul {
    @include type-s-to-m; } }
