.next-workshop {
  @include unit(padding-top, .5);
  @include unit(padding-bottom, 1);
  background: $color-light-grey;
  .teaser {
    @include unit(margin-bottom, -.5);
    background: $color-light-grey; } }

.next-workshop__banner {
  position: relative; }

.next-workshop__headline {
  @include type-ml-to-xl;
  margin-bottom: em(20);
  font-weight: 400;
  text-align: center; }
