.module__downloads {
  @include unit(margin-bottom, 1);
  h2 {
    @include unit(margin-bottom, 0.3);
    @include bp(min, $bp-3) {
      @include unit(margin-bottom, 0.1); } }
  @include bp(min, $bp-3) {
    position: absolute; }
  .module__downloads--inner {
    display: flex;
    justify-content: center;
    .download-item {
      //background: $color-light-grey
      border: 1px solid $black;
      padding: em(30) em(40);
      @include bp(min, $bp-3) {
        padding: em(30) em(40); }
      &:first-of-type {
        @include unit(margin-right, 1);
        @include bp(min, $bp-3) {
          @include unit(margin-right, 0.1); } }
      &:hover {
        color: $color-red;
        //background: $color-light-red
        border: 1px solid $color-red; } } } }
