@font-face {
  font-family: 'RealHead';
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/RealHeadWebPro-Bold.woff") format("woff"); }

@font-face {
  font-family: 'RealText';
  font-weight: 700;
  font-style: normal;
  src: url("../assets/fonts/RealTextWebPro-Bold.woff") format("woff");

  @font-face {}
  font-family: 'RealText';
  font-weight: 700;
  font-style: italic;
  src: url("../assets/fonts/RealTextW01-BoldItalic.woff2") format("woff"); }

// @font-face
//   font-family: 'RealText'
//   font-weight: 800
//   font-style: normal
//   src: url("../assets/fonts/RealTextW01-Extrabold.woff2") format("woff")

// @font-face
//   font-family: 'RealText'
//   font-weight: 900
//   font-style: normal
//   src: url("../assets/fonts/RealTextW01-Black.woff2") format("woff")

@font-face {
  font-family: 'RealText';
  font-weight: 300;
  font-style: normal;
  src: url("../assets/fonts/RealTextWebPro-Book.woff") format("woff"); }

@font-face {
  font-family: 'RealText';
  font-weight: 300;
  font-style: italic;
  src: url("../assets/fonts/RealTextItalic-Book.woff") format("woff"); }

@font-face {
  font-family: 'RealText';
  font-weight: 200;
  font-style: normal;
  src: url("../assets/fonts/RealTextWebPro-Light.woff") format("woff"); }
