.homepage-feature {
  @include unit(margin-bottom, .7); }

.homepage-full {
  display: flex;
  align-items: center;
  justify-content: center;
  .homepage-full__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 70vh;
    @include bp(min, $bp-6) {
      height: 55vh; } }
  div.track-nav {
    font-weight: 700;
    line-height: 1;
    width: 70vw;
    display: flex;
    align-items: stretch;
    &.hidden {
      display: none; }
    * {
      color: $color-red; }
    >div {
      text-align: center;
      flex: 1; }
    a:hover {
      font-style: italic;
      line-height: 0.7; } }
  div.homepage-intro {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    div.homepage-intro__inner {
      color: white;
      width: 70vw;
      padding: 1.4em 2.8em 2.8em 2.8em;
      background: rgba(0,0,0,0.8);
      max-width: 800px;
      &.visible {
        visibility: visible; }
      &.visibility-hidden {
        visibility: hidden; }
      p {
        font-size: 1.4em;
        margin-bottom: 1em; } }
    @include bp(min, $bp-4) {
      width: 65vw; }
    @include bp(min, $bp-5) {
      width: 55vw; }
    @include bp(min, $bp-6) {
      width: 45vw; } }
  img.logo-home {
    cursor: pointer;
    width: 300px;
    margin: 0 auto 1em auto;
    display: block; } }
