.cart-table {
  display: inline-block;
  width: 100%;
  .button {
    display: inline-block; } }

.cart-table-row {
  width: 100%;
  display: inline-block;
  margin-bottom: rem(20); }

.cart-table-row--clear {
  @include clearfix;
  border-bottom: 1px solid #ddd;
  padding-top: rem(20);
  &:first-of-type {
    border-bottom: none; }
  &:last-of-type {
    border-top: 1px solid #ddd;
    border-bottom: none; }
  &.is-hidden-on-mobile {
    @include bp(max, $bp-3) {
      display: none; } } }
.cart-table--half {
  @include bp(min, $bp-3) {
    display: block;
    float: left;
    width: 50% !important; } }

.cart-table--third {
  @include bp(min, $bp-3) {
    display: block;
    float: left;
    width: 33.33% !important; } }

.cart-table--fourth {
  @include bp(min, $bp-3) {
    display: block;
    float: left;
    width: 25% !important; } }

.cart-table-cell {
  width: 100%;
  padding-top: rem(10);
  padding-bottom: rem(10);
  @include bp(min, $bp-3) {
    padding-left: rem(10);
    padding-right: rem(10); } }

.cart-table-cell--head {
  padding-top: rem(5);
  padding-bottom: rem(5);
  border-bottom: 1px solid #ddd; }

.cart-table--alignright {
  @include bp(min, $bp-3) {
    text-align: right; } }

.cart-table-row--hr {
  border-bottom: 1px solid #ddd; }

.cart-table__image {
  max-width: 150px; }

.cart-table__total-price {
  border-top: 1px solid #000;
  padding-top: 5px;
  margin-top: 5px;
  display: inline-block; }
