.header {
  // +unit(padding-left, 0.5)
  // +unit(padding-right, 0.5)
  // +unit(max-width, 12)
  // position: fixed
  // top: 0
  // left: 50%
  // width: 100%
 }  // transform: translateX(-50%)

.header__inner {
  // width: 100%
  // padding: em(20)
  // background: #fff
  // margin-left: auto
  // margin-right: auto
  // line-height: 1
  // +bp(min, $bp-3)
 }  //   padding: em(20) em(40)

.header {
  position: fixed;
  width: 100vw;
  background: white; }


.header__inner {
  padding: em(20);
  //max-width: 1760px
  margin: 0 auto;
  @include unit(padding-left, 0.5);
  @include unit(padding-right, 0.5);
  @include unit(max-width, 12);
  // display: flex
  // align-items: center
  justify-content: space-between;
  // +bp(min, $bp-3)
 }  //   padding: em(20) em(40)
