.slider-gallery-outer-wrapper {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: rgba(0,0,0,0.85);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	div.slider {
		// max-width: 80vw
		// max-height: 60vh
		img {
			max-width: 80vw;
			max-height: 70vh; } } }

div.gallery-controls {
	max-width: 50px;
	margin: 0 12px;
	svg {
		max-width: 100%;
		cursor: pointer;
		&:focus {
			outline: none; } } }
div.close {
	z-index: 99999;
	position: fixed;
	top: 12px;
	right: 0;
	text-align: right;
	svg {
		width: 80%; } }
