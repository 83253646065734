figure {
  overflow: hidden;
  img {
    display: block;
    width: 100%; }

  figcaption {
    @include font-size($font-size-m);
    font-style: normal;
    margin-top: .5em;
    color: $color-caption;
    text-align: left; } }
