.modal-fade {
  background: rgba(0,0,0,0.7);
  z-index: 9999;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.modal {
  min-width: auto;
  max-width: fit-content;
  video {
    max-width: 80vw;
    max-height: 80vh; } }
