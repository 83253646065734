.next-workshop {
  background: #EDEDED;
  margin-top: em(20);
  padding-top: em(40);
  padding-bottom: em(20);
  @include bp(min, $bp-3) {
    margin-top: em(60);
    padding-top: em(60);
    padding-bottom: em(40); } }

.next-workshop .teaser {
  background: #EDEDED; }

.next-workshop h2 {
  @include type-ml-to-xl;
  padding-left: em(20);
  padding-right: em(20);
  padding-bottom: em(20);
  font-weight: 300;
  text-align: center; }

.next-workshop:empty {
  margin: 0;
  padding: 0; }
