
$bp-0: 320;
$bp-1: 420;
$bp-2: 576;
$bp-3: 768;
$bp-4: 1024;
$bp-5: 1280;
$bp-6: 1440;
$bp-8: 1920;

$grid-column-count: 12;
$grid-unit: 100 / $grid-column-count * 1vw;
$grid-fluid-max-width: $bp-8;

$fixed-gutter: 2rem;

$max-width--page: 1920;

$font-size-xs: 10;
$font-size-s: 12;
$font-size-m: 16;
$font-size-ml: 20;
$font-size-l: 29;
$font-size-xl: 36;
$font-size-xxl: 70;
$font-size-huge: 80;
$font-size-giga: 150;

$line-height-s: 1.5;
$line-height-m: 1.5;
$line-height-ml: 1.5;
$line-height-l: 1.3;
$line-height-xl: 1.2;
$line-height-xxl: 1.2;
$line-height-huge: 1.2;

$font-family: 'RealText', Helvetica, serif;

$black: #000;
$white: #fff;

$color-red: #FF2A0E;
$color-light-red: #FAE5E5;
$color-light-grey: #EDEDED;
$color-caption: #9B9B9B;
