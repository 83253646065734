.module {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-top: em(20);
  padding-bottom: em(20);
  @include bp(min, $bp-3) {
    padding-top: em(40);
    padding-bottom: em(20); } }

.module__inner {
  @include unit(padding-left, 0.5);
  @include unit(padding-right, 0.5);
  @include unit(max-width, 12);
  margin-left: auto;
  margin-right: auto; }
