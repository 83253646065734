@mixin font-size($size) {
  font-size: rem($size);
  @if $size == $font-size-s {
    line-height: $line-height-s; }
  @if $size == $font-size-m {
    line-height: $line-height-m; }
  @if $size == $font-size-ml {
    line-height: $line-height-ml; }
  @if $size == $font-size-l {
    line-height: $line-height-l; }
  @if $size == $font-size-xl {
    line-height: $line-height-xl; }
  @if $size == $font-size-huge {
    line-height: $line-height-huge; } }

@mixin line-height($base, $breakpoints, $sizes) {
  line-height: rem($base);
  $i: 0;
  @each $break in $breakpoints {
    $i: $i + 1;
    @include bp(min, $break) {
      line-height: rem(nth($sizes, $i)); } } }

@mixin type-zoom($base-size, $breakpoints, $sizes) {
  @include font-size($base-size);
  $i: 1;
  @each $break in $breakpoints {
    @include bp(min, $break) {
      @include font-size(nth($sizes, $i)); }
    $i: $i + 1; } }

.type-s {
  @include font-size($font-size-s); }
.type-m {
  @include font-size($font-size-m); }
.type-ml {
  @include font-size($font-size-ml); }
.type-l {
  @include font-size($font-size-l); }
.type-xl {
  @include font-size($font-size-xl); }
.type-huge {
  @include font-size($font-size-huge); }
.type-giga {
  @include font-size($font-size-giga); }

@mixin type-xs-to-s {
  @include type-zoom($font-size-xs, $bp-3, $font-size-s); }
.type-xs-to-s {
  @include type-xs-to-s; }

@mixin type-s-to-ml {
  @include type-zoom($font-size-s, $bp-3 $bp-4, $font-size-m $font-size-ml); }
.type-s-to-ml {
  @include type-s-to-ml; }

@mixin type-xs-to-m {
  @include type-zoom($font-size-xs, $bp-3 $bp-4, $font-size-s $font-size-m); }
.type-xs-to-m {
  @include type-xs-to-m; }

@mixin type-xs-to-l {
  @include type-zoom($font-size-xs, $bp-3 $bp-4, $font-size-s $font-size-l); }
.type-xs-to-l {
  @include type-xs-to-m; }

@mixin type-m-to-ml {
  @include type-zoom($font-size-m, $bp-3, $font-size-ml); }
.type-m-to-ml {
  @include type-m-to-ml; }

@mixin type-m-to-l {
  @include type-zoom($font-size-m, $bp-2 $bp-3, $font-size-ml $font-size-l); }
.type-m-to-l {
  @include type-m-to-l; }

@mixin type-s-to-m {
  @include type-zoom($font-size-s, $bp-3, $font-size-m); }
.type-s-to-m {
  @include type-s-to-m; }

@mixin type-l-to-xl {
  @include type-zoom($font-size-l, $bp-3, $font-size-xl); }
.type-l-to-xl {
  @include type-l-to-xl; }

@mixin type-l-to-xxl {
  @include type-zoom($font-size-l, $bp-3 $bp-4, $font-size-xl $font-size-xxl); }
.type-l-to-xxl {
  @include type-l-to-xxl; }

@mixin type-ml-to-xl {
  @include type-zoom($font-size-ml, $bp-3 $bp-4, $font-size-l $font-size-xl); }
.type-ml-to-xl {
  @include type-ml-to-xl; }

@mixin type-ml-to-xxl {
  @include type-zoom($font-size-ml, $bp-2 $bp-3 $bp-4, $font-size-l $font-size-xl $font-size-xxl); }
.type-ml-to-xxl {
  @include type-ml-to-xxl; }

@mixin type-ml-to-l {
  @include type-zoom($font-size-ml, $bp-3, $font-size-l); }
.type-ml-to-l {
  @include type-ml-to-l; }

@mixin type-xxl-to-giga {
  @include type-zoom($font-size-xxl, $bp-3, $font-size-giga); }
.type-xxl-to-giga {
  @include type-xxl-to-giga; }

@mixin type-huge-to-giga {
  @include type-zoom($font-size-huge, $bp-4, $font-size-giga); }
.type-huge-to-giga {
  @include type-huge-to-giga; }

@mixin type-tiers {
  @include type-zoom($font-size-huge, $bp-3 $bp-4 $bp-5 $bp-6, ($font-size-xl * 1.5) $font-size-xxl $font-size-huge $font-size-giga); }
.type-tiers {
  @include type-tiers; }

@mixin headline {
  font-family: $font-family; }
.headline {
  @include headline; }

.text-link {
  @include headline;
  @include transition(color, 0.25s);
  display: inline-block;
  text-transform: uppercase; }

.tac {
  text-align: center; }

.tal {
  text-align: left; }

.tar {
  text-align: right; }

.as-h1 {
  @include headline;
  @include type-ml-to-xl;
  margin-bottom: em(10);
  font-weight: 400; }

.type-display {
  font-size: 3.5em; }
