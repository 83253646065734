.row {
  margin-left: em(20);
  margin-right: em(20);
  @include bp(min, $bp-3) {
    margin-left: em(40);
    margin-right: em(40); } }

.container:after,
.row:after,
.u-cf {
  content: "";
  display: table;
  clear: both; }

.row--button {
  margin-top: em(20); }

.shop {
  h2 {
    @include type-ml-to-l;
    margin-bottom: rem(20);
    font-weight: 400; } }

.has-padding-top {
  @include unit(padding-top, 2.5);
  @include bp(min, $bp-2) {
    @include unit(padding-top, 1.5); }
  @include bp(min, $bp-3) {
    @include unit(padding-top, 1); }
  @include bp(min, $bp-5) {
    @include unit(padding-top, .75); } }

.has-mobile-paddding {
  @include bp(max, $bp-3) {
    @include unit(padding-top, 1.8); } }
