.intro {
  padding-bottom: 0;
  p {
    max-width: 750px;
    font-size: rem(20);
    @include bp(min, $bp-3) {
      //padding-left: rem(40)
      font-size: rem(24); } }
  a, a:link {
    border-bottom: 1px solid #000;
    &:hover {
      border-bottom: none;
      color: $color-red; } }
  a {
    @include transition(color, 0.25s); }
  em {
    font-style: italic; } }
