.body-text {
  margin: 0 auto;
  margin-bottom: 0em;
  h1, h2 {
    @include headline;
    font-weight: bold;
    margin-bottom: 2em; }
  p,
  ul, ol, address {
    @include type-m-to-ml;
    font-weight: normal; }
  p, address {
    position: relative;
    margin-bottom: 0.8em;
    strong, b {
      font-weight: bold; }
    em, i {
      font-style: italic; }
    &:last-child {
      margin-bottom: 0; } }
  h1 {
    @include type-l-to-xl;
    margin-bottom: 2em;
    margin-top: 0; }
  h2 {
    @include type-ml-to-l;
    margin-bottom: 1.5em;
    margin-top: 3em; }
  h3, h4, h5, h6 {
    @include type-m-to-ml;
    margin-top: 2em;
    margin-bottom: 2em; }
  strong {
    font-weight: bold !important; }
  figure {
    margin-top: 2em;
    margin-bottom: 2em;
    img {
      width: auto; } }
  ul, ol {
    margin: 1.5em auto 1.5em auto;
    li {
      margin-bottom: .2em; } }
  ol {
    li {
      position: relative;
      list-style-position: inside;
      list-style-type: none;
      padding-left: 2em;
      counter-increment: list-item;
      &:before {
        @include headline;
        position: absolute;
        top: 0;
        left: 0;
        width: 1.5em;
        content: counter(list-item) '.';
        font-size: 90%;
        text-align: right;
        font-weight: bold; } } }
  ul {
    li {
      position: relative;
      padding-left: 1.1em;
      &:before {
        content: '•';
        font-weight: bold;
        position: absolute;
        left: 0;
        top: 0; } } }
  a, a:link {
    border-bottom: 1px solid #000;
    &:hover {
      border-bottom: none;
      color: $color-red; } }
  a {
    @include transition(color, 0.25s); }
  &.is-small {
    max-width: none;
    p,
    h3, h4, h5, h6,
    ul, ol, address {
      @include type-s-to-m; } }
  &.is-large {
    max-width: none;
    p,
    h3, h4, h5, h6,
    ul, ol, address {
      @include type-ml-to-l; } }
  &.has-offsets {
    max-width: 850px;
    @include bp(min, $bp-2) {
      p, ul, ol, figure {
        @include unit(padding-left); }
      h1, h2, h3, h4, h5, h6 {
        @include unit(padding-right); } } }
  @include bp(min, $bp-4) {
    @include unit(max-width, 5); } }

.scale-to-body-text.scale-to-body-text {
  display: block;
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.scale-to-offset-body-text.scale-to-offset-body-text {
  display: block;
  @include unit(padding-right);
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }
