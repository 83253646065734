.teaser {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  //max-width: 638px
  background: #fff;
  //padding-left: em(20)
  //padding-right: em(20)
  // +bp(min, $bp-3)
  //   padding-left: em(40)
  //   padding-right: em(40)
  // +bp(min, $bp-8)
 }  //   +unit(margin-left, 0.5)

.product--journal .two-up .cell .teaser {
  @include bp(min, $bp-5) {
    max-width: none; } }

.teaser__inner {
  h1 {
    @include type-ml-to-l;
    margin-bottom: em(5);
    font-weight: 400;
    line-height: 1.25; }
  .button {
    margin-bottom: 0; }
  @include bp(min, $bp-3) {
    border-width: 2px; } }

.teaser__inner__date {
  display: inline-block;
  width: 100%;
  margin-bottom: em(15);
  font-size: rem(16);
  @include bp(min, $bp-3) {
    font-size: rem(18); }
  span {
    margin-left: em(10); } }

.teaser--product {
  position: relative;
  margin-left: 0;
  padding-left: 0;
  padding-right: 0;
  &:hover {
    .teaser--product__image__inner {
      border-color: $color-red; }
    .teaser__inner {
      border-color: $color-red;
      color: $color-red; }
    .button {
      border-color: $color-red;
      color: $color-red; }
    .teaser--product__price span {
      color: $color-red; } } }

.teaser--product .teaser__inner {
  padding-top: em(20); }

.teaser--product__image {
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 2px solid #FFF;
  &:before {
    content: "";
    display: block;
    padding-top: 100%; } }

.product--journal .two-up .cell .teaser--product__image::before {
  padding-top: 50%; }

.teaser--product__image__inner {
  height: 100%;
  overflow: hidden;
  img {
    position: absolute;
    top: calc(50% - 2px);
    left: -2px;
    width: calc(100% + 6px);
    max-width: calc(100% + 6px);
    transform: translateY(-50%); } }

.teaser--product__price {
  width: 100%;
  margin-bottom: em(10);
  color: $color-red;
  font-size: rem(16);
  @include bp(min, $bp-3) {
    font-size: rem(18); }
  span {
    margin-left: em(10);
    color: $color-red; } }

article.main {
  .teaser {
    .teaser__inner {
      @include unit(padding-left, 0.5);
      @include unit(padding-right, 0.5);
      @include unit(max-width, 12);
      margin-left: auto;
      margin-right: auto;
      // h1, p
      //   +bp(min, $bp-5)
 } } }      //   padding-left: rem(40)
