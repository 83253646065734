.has-currency-sign {
  display: inline-block;
  -webkit-font-feature-settings: 'tnum';
  -moz-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  white-space: nowrap;
  strike {
    opacity: .5;
    margin-right: .25em;
    color: $color-red; } }

.has-currency-sign--top {
  vertical-align: top; }

.has-currency-sign .is-currency-sign {
  margin-right: 0.1785em;
  font-size: 80%; }
