.social-icon-list {
  text-align: center;
  li {
    display: inline-block; } }

.social-icon {
  display: inline-block;
  width: em(26);
  height: em(26);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 1px;
  background-color: #000;
  @include bp(min, $bp-3) {
    width: em(36);
    height: em(36);
    border-radius: 2px; } }

.social-icon:hover {
  background-color: $color-red; }

.social-icon--facebook {
  background-image: url("../assets/icons/facebook.svg"); }

.social-icon--twitter {
  background-image: url("../assets/icons/twitter.svg"); }

.social-icon--mail {
  background-image: url("../assets/icons/mail.svg"); }
