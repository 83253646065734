.logo {
  line-height: 1;
  display: inline-block;
  width: 200px;
  text-transform: lowercase;
  a {
    display: block; }
  h1 {
    text-indent: -99999px;
    max-width: 0;
    max-height: 0;
    font-weight: 400; }
  img {
    display: block;
    line-height: 1; } }
