.banner {
  position: relative;
  width: 100%;
  height: 80vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  &__full {
    height: 100vh; } }

.banner--small {
  @include unit(max-width, 12);
  @include unit(margin-bottom, 2);
  margin-left: auto;
  margin-right: auto;
  max-height: 700px;
  @include bp(min, $bp-5) {
    @include unit(margin-bottom, 1); }
  .banner__teaser {
    max-height: 700px; } }

.banner--small .teaser {
  @include unit(margin-bottom, -0.5); }

.banner__teaser {
  //+unit(margin-left, 0.5)
  //+unit(margin-right, 0.5)
  //+unit(max-width, 12)
  position: relative;
  height: 80vh;
  // +bp(min, $bp-8)
  //   margin-left: auto
  //   margin-right: auto
  &.has-link h1:hover {
    color: $color-red; } }

.banner__teaser .teaser {
  padding-top: rem(20);
  @include bp(min, $bp-3) {
    padding-top: em(40); } }

.banner__teaser .teaser__inner {
  border-bottom: 0; }

.banner__teaser .banner__video-play-button {
  height: calc(100% - 70px);
  display: flex;
  justify-content: center;
  align-items: center; }
