.nav {
  display: inline-block;
  margin-top: em(20);
  text-transform: lowercase;
  line-height: 1.5;
  @include bp(min, $bp-4) {
    float: right;
    margin-top: 0; } }

.nav__list {
  display: block;
  width: 100%; }

.nav__list__item {
  display: inline-block;
  width: 100%;
  padding-top: em(5);
  padding-bottom: em(5);
  font-size: rem(16);
  @include bp(min, $bp-4) {
    width: auto;
    margin-left: em(30);
    padding: 0;
    font-size: rem(18); } }

.nav__list__item a:hover {
  color: $color-red;
  border-bottom: 1px solid $color-red;
  padding-bottom: em(2); }

.nav__list__item .active {
  border-bottom: 1px solid #000;
  padding-bottom: em(2); }

.nav__list__item--shop {
  @include bp(min-max, $bp-0, $bp-3) {
    margin-top: em(10);
    padding-top: em(8);
    border-top: 1px solid #000; }
  @include bp(min, $bp-3) {
    margin-top: 0;
    padding-left: em(30);
    border-left: 2px solid #000; } }

.nav-toggle {
  float: right;
  line-height: 1.8; }

.shop-nav {
  width: 100%;
  margin-top: -(em(20));
  margin-bottom: em(20);
  padding: em(10) 0;
  border-bottom: 2px solid #000;
  font-size: rem(14);
  text-transform: uppercase;
  letter-spacing: 1px; }

.shop-nav .right {
  float: right; }

.shop-nav ul {
  display: inline-block;
  margin: 0; }

.shop-nav li {
  display: inline-block;
  margin-bottom: 0;
  list-style: none;
  @include bp(min, $bp-3) {
    margin-right: em(14); }
  @include bp(min-max, $bp-0, $bp-3) {
    display: block;
    width: 100%; } }

.shop-nav a:hover {
  color: $color-red; }
