.module--footer {
  @include type-s-to-m; }

.footer__copyright {
  margin-top: em(10);
  padding-top: em(10);
  border-top: 2px solid #000; }

.footer__copyright a:hover {
  color: $color-red; }
