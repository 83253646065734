.video-play-button {
  // border: 2px solid #ffffff
  border-radius: 50%;
  // padding: 20px
  cursor: pointer;
  svg {
    width: 10vw;
    min-width: 100px;
    max-width: 150px;
    display: block;
    path {
      fill: #ffffff;
      transition: fill .2s ease-out; } }
  &:hover {
    svg {
      path {
        fill: $color-red; } } } }
