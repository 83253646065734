.pagination {
  @include unit(max-width, 12);
  @include unit(padding-left, 0.5);
  @include unit(padding-right, 0.5);
  padding-top: em(40);
  padding-bottom: em(40);
  margin-left: auto;
  margin-right: auto; }

.pagination__inner {
  padding-left: em(20);
  padding-right: em(20);
  text-align: center;
  @include bp(min, $bp-3) {
    padding-left: em(40);
    padding-right: em(40); } }

.pagination span,
.pagination a {
  display: inline-block;
  background: #fff;
  margin-left: em(4);
  margin-right: em(4);
  padding: em(10) em(16);
  border: 2px solid #000;
  font-size: rem(14);
  text-align: center;
  @include bp(min, $bp-3) {
    font-size: rem(16); } }

.pagination span {
  background: #000;
  color: #fff; }
