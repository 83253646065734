.image__full-width {
  @include unit(max-width, 10);
  display: block;
  margin-left: auto;
  margin-right: auto;
  @include bp(min, $bp-3) {
    @include unit(max-width, 8); } }

.image--half-width {
  text-align: center;
  .module__inner {
    @include unit(max-width, 9);
    display: inline-block;
    width: 100%;
    margin-bottom: -($fixed-gutter*2) !important;
    @include bp(max, $bp-3) {
      @include unit(max-width, 10);
      @include unit(padding-left, 0);
      @include unit(padding-right, 0); } } }

.image--three-up {
  text-align: center;
  .module__inner {
    @include unit(max-width, 9);
    @include bp(max, $bp-3) {
      @include unit(max-width, 10);
      @include unit(padding-left, 0);
      @include unit(padding-right, 0); }
    display: block;
    margin-bottom: -($fixed-gutter*2) !important;
    figcaption {
      text-align: left; } } }

.image--left .image__full-width {
  @include unit(max-width, 10);
  margin-left: 0;
  width: 100%;
  @include bp(min, $bp-3) {
    @include unit(max-width, 6); }
  figcaption {
    @include unit(max-width, 10);
    @include bp(min, $bp-3) {
      @include unit(max-width, 4); } } }

.image--full {
  .module__inner {
    @include unit(padding-left, 0);
    @include unit(padding-right, 0); }
  .image__full-width {
    @include unit(max-width, 12);
    width: 100%; }
  figcaption {
    @include unit(max-width, 10);
    margin-right: auto;
    margin-left: auto;
    @include bp(min, $bp-3) {
      @include unit(max-width, 8); } } }

.image__list-item + .image__list-item {
  margin-top: $fixed-gutter; }
