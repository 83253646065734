input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="date"],
textarea,
select {
  height: rem(38);
  margin-bottom: rem(6);
  padding: rem(6) rem(10);
  background-color: #E8E8E8;
  font-size: rem(16);
  border: none;
  outline: none;
  border-radius: 2px;
  box-shadow: none;
  box-sizing: border-box; }


input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="date"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: $font-family;
  font-variant-numeric: lining-nums; }


textarea {
  min-height: rem(65);
  padding-top: rem(6);
  padding-bottom: rem(6); }


input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
  border: 2px solid #000;
  outline: 0; }


label,
legend {
  font-variant-numeric: lining-nums;
  display: block;
  margin-bottom: .5rem;
  font-weight: 700;
  &.radio-custom-styled {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: .4em; } }


fieldset {
  padding: 0;
  border-width: 0; }


input[type="checkbox"],
input[type="radio"] {
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 2px solid #D1D1D1;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-right: .2em;
  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $color-red; }
  &:checked {
    border: 2px solid $color-red; } }

input[type="radio"]:checked::before {
  transform: scale(1); }

input[type="submit"],
button[type="submit"] {
  height: rem(38);
  margin-bottom: rem(6);
  padding: rem(6) rem(50);
  background-color: $color-red;
  color: white;
  font-size: rem(16);
  border-radius: 2px;
  box-shadow: none;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  cursor: pointer; }

label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: normal; }

form#inquiry,
form#subscription-form {
  .form-fields.u-flex {
    display: block;
    @include bp(min, $bp-4) {
      display: flex;
      justify-content: space-between; }
    .field-wrapper {
      &.u-flex {
        display: block;
        @include bp(min, $bp-4) {
          display: flex;
          justify-content: space-between;
          >* {
            width: 49%; } } } }
    textarea,
    select,
    input[type="text"] {
      width: 100%; }
    > div {
      &:first-child {
        flex: 0 0 35%; }
      &:last-child {
        flex: 0 0 62%; } } } }
select {
  -webkit-appearance: none;
  background: #E8E8E8 url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB2aWV3Qm94PSIwIDAgNC45NSAxMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4uY2xzLTF7ZmlsbDojRThFOEU4O30uY2xzLTJ7ZmlsbDojNDQ0O308L3N0eWxlPgogIDwvZGVmcz4KICA8dGl0bGU+YXJyb3dzPC90aXRsZT4KICA8cmVjdCBjbGFzcz0iY2xzLTEiIHdpZHRoPSI0Ljk1IiBoZWlnaHQ9IjEwIi8+CiAgPGcgc3R5bGU9IiIgdHJhbnNmb3JtPSJtYXRyaXgoMC4zNTgyNiwgMCwgMCwgMC4zNTU4MzMsIC0xMTcuMjEzMTA0LCAtODIuMDkzNDM3KSI+CiAgICA8cGF0aCBkPSJNMzI5LjQxNTQwMCwyNDYuNzYxMDAwIEwzMzIuODU4MjAwLDI0OS43NDc4MDAgTDMzNS4xODM4MDAsMjQ5Ljc0NzgwMCBMMzM4LjYyNjYwMCwyNDYuNzYxMDAwIEwzMzcuNzgzMDAwLDI0NS41NzU0MDAgTDMzNC4wMjEwMDAsMjQ3LjYwNDYwMCBMMzMwLjI1OTAwMCwyNDUuNTc1NDAwIEwzMjkuNDE1NDAwLDI0Ni43NjEwMDAgWiAiIHN0eWxlPSJmaWxsOiByZ2IoNjgsIDY4LCA2OCk7IiBzdHJva2U9Im5vbmUiLz4KICA8L2c+CiAgPGcgc3R5bGU9IiIgdHJhbnNmb3JtPSJtYXRyaXgoMC4zNTY3OTUsIDAsIDAsIDAuMzU2Nzk1LCAtMTE2LjcxMTEyMSwgLTg0LjQyMzM3KSI+CiAgICA8cGF0aCBkPSJNMzM4LjYyNjYwMCwyNDguNTYyMjAwIEwzMzUuMTgzODAwLDI0NS41NzU0MDAgTDMzMi44NTgyMDAsMjQ1LjU3NTQwMCBMMzI5LjQxNTQwMCwyNDguNTYyMjAwIEwzMzAuMjU5MDAwLDI0OS43NDc4MDAgTDMzNC4wMjEwMDAsMjQ3LjcxODYwMCBMMzM3Ljc4MzAwMCwyNDkuNzQ3ODAwIEwzMzguNjI2NjAwLDI0OC41NjIyMDAgWiAiIHN0eWxlPSJmaWxsOiByZ2IoNjgsIDY4LCA2OCk7IiBzdHJva2U9Im5vbmUiLz4KICA8L2c+Cjwvc3ZnPg==) no-repeat 98% 50%; }
