//======================
// Spacing
//======================

$page-max-width: 1920px;

.imgix-fluid:not(.imgix-fluid-bg) {
  display: block; }

.mt0 {
  margin-top: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mt3 {
  margin-top: 3em !important; }

.mt5 {
  margin-top: 5em !important; }

.mb {
  margin-bottom: em(20) !important; }

.mb1 {
  margin-bottom: 1em !important; }

.mb2 {
  margin-bottom: 2em !important; }

.p2 {
  padding: 2em !important; }

.p3 {
  padding: 3em !important; }

.u-flex {
  display: flex; }

.u-block {
  display: block; }

.u-flex-centered {
  justify-content: center;
  align-items: center; }

.u-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

body.freeze {
  overflow: hidden; }

.hidden {
  display: none; }

.lining-nums {
  font-variant-numeric: lining-nums; }

.text-white {
  color: white; }

.text-red {
  color: #FF2A0E; }

.text-fw-800 {
  font-weight: 800; }

.freeze {
  overflow: hidden; }
