/*! responsive-nav.js 1.0.39 by @viljamis */

.nav-collapse ul {
  margin: 0;
  width: 100%;
  display: block;
  list-style: none; }

.js .nav-collapse {
  clip: rect(0 0 0 0);
  max-height: 0;
  position: absolute;
  display: block;
  overflow: hidden;
  zoom: 1; }

.nav-collapse.opened {
  max-height: 9999px; }

.nav-toggle {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

@media screen and (min-width: 1024px) {
  .js .nav-collapse {
    position: relative; }
  .js .nav-collapse.closed {
    max-height: none; }
  .nav-toggle {
    display: none; } }
